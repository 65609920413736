import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom'
import React from 'react'
import * as urlUtils from 'common/utils/urlUtils'
import { useFloatHover } from '../../../hooks/useFloatHover'
import { LanguageSwitcherInterface } from '../../../types/entities/LanguageSwitcherInterface'
import CurrentLangUi from './ui/LanguageSwitcherCurrentLangUi'
import LanguageSwitcherItemsLinkUi from './ui/LanguageSwitcherItemsLinkUi'
import LanguageSwitcherItemsUi from './ui/LanguageSwitcherItemsUi'
import LanguageSwitcherUi from './ui/LanguageSwitcherUi'

export const localeKeys = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  it: 'it',
  pt: 'pt',
  de: 'de',
  nl: 'nl',
  ru: 'ru',
  jp: 'jp', // why not ja? @see https://systeme-team.slack.com/archives/G61JS7GDC/p1684234537974629
  ar: 'ar',
  tr: 'tr',
  zh: 'zh',
}

export const localeNames = {
  [localeKeys.en]: 'English',
  [localeKeys.fr]: 'Français',
  [localeKeys.es]: 'Español',
  [localeKeys.it]: 'Italiano',
  [localeKeys.pt]: 'Português',
  [localeKeys.de]: 'Deutsch',
  [localeKeys.nl]: 'Nederlands',
  [localeKeys.ru]: 'Русский',
  [localeKeys.jp]: '日本語',
  [localeKeys.ar]: 'عربي',
  [localeKeys.tr]: 'Türkçe',
  [localeKeys.zh]: '中國人',
}

interface LanguageSwitcherProps
  extends Omit<
    LanguageSwitcherInterface,
    'id' | 'type' | 'parentId' | 'appearance' | 'htmlAttrId'
  > {
  currentLanguage: string
  isEditing?: boolean
}

function NewLanguageSwitcher({
  color,
  margin,
  fontSize,
  fontStyle,
  fontFamily,
  fontWeight,
  lineHeight,
  mobileFontSize,
  mobileLineHeight,
  mobileFontStyle,
  mobileFontFamily,
  mobileFontWeight,
  mobileMargin,
  mobileColor,
  currentLanguage,
  redirectPath,
  excludedLanguages,
}: LanguageSwitcherProps) {
  const { show, delayOpen, delayClose } = useFloatHover()

  const { refs, floatingStyles } = useFloating<HTMLDivElement>({
    placement: 'bottom',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    open: show,
    strategy: 'absolute',
  })

  const getUrl = (locale: string): string => {
    if (redirectPath === undefined) {
      return locale === localeKeys.en ? `/` : `/${locale}`
    }

    const clearRedirectPath =
      urlUtils.trimLeadingAndTrailingSlashes(redirectPath)

    return locale === localeKeys.en
      ? `/${clearRedirectPath}`
      : `/${locale}/${clearRedirectPath}`
  }

  return (
    <LanguageSwitcherUi margin={margin} mobileMargin={mobileMargin}>
      <CurrentLangUi
        fontSize={fontSize}
        color={color}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        lineHeight={lineHeight}
        mobileColor={mobileColor}
        mobileFontSize={mobileFontSize}
        mobileFontFamily={mobileFontFamily}
        mobileFontWeight={mobileFontWeight}
        mobileFontStyle={mobileFontStyle}
        mobileLineHeight={mobileLineHeight}
        onMouseEnter={delayOpen}
        onMouseLeave={delayClose}
        ref={refs.setReference}
      >
        {currentLanguage.charAt(0).toUpperCase() + currentLanguage.slice(1)}
      </CurrentLangUi>
      {show && (
        <LanguageSwitcherItemsUi
          ref={refs.setFloating}
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          isOpened={show}
          style={floatingStyles}
        >
          {Object.keys(localeNames)
            .filter(countryKey => countryKey !== currentLanguage)
            .filter(countryKey => !excludedLanguages?.includes(countryKey))
            .map(countryKey => (
              <LanguageSwitcherItemsLinkUi
                key={countryKey}
                href={getUrl(countryKey)}
              >
                {localeNames[countryKey]}
              </LanguageSwitcherItemsLinkUi>
            ))}
        </LanguageSwitcherItemsUi>
      )}
    </LanguageSwitcherUi>
  )
}

export default NewLanguageSwitcher
